import React, { useState, useEffect } from "react";

const RecipientsManager = ({
  users = [],
  recipients = [],
  onRecipientsChange,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [selectedRecipients, setSelectedRecipients] = useState(recipients);
  const [inputValue, setInputValue] = useState("");

  // Filter the user list when the search term changes
  useEffect(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    setFilteredUsers(
      users.filter(
        (user) =>
          user.name.toLowerCase().includes(lowerCaseSearchTerm) ||
          user.email.toLowerCase().includes(lowerCaseSearchTerm) ||
          user.phone.includes(lowerCaseSearchTerm) ||
          lowerCaseSearchTerm === ""
      )
    );
  }, [searchTerm, users]);

  // Add a recipient (either from the user list or an arbitrary value)
  const handleAddRecipient = (value) => {
    if (value && !selectedRecipients.includes(value)) {
      const updatedRecipients = [...selectedRecipients, value];
      setSelectedRecipients(updatedRecipients);
      onRecipientsChange(updatedRecipients);
      setInputValue(""); // Clear the input after adding
    }
  };

  // Remove a recipient
  const handleRemoveRecipient = (value) => {
    const updatedRecipients = selectedRecipients.filter(
      (recipient) => recipient !== value
    );
    setSelectedRecipients(updatedRecipients);
    onRecipientsChange(updatedRecipients);
  };

  // Handle input for adding arbitrary values (emails/phone numbers)
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value); // Update search term
    setInputValue(e.target.value);
  };

  // Detect Enter key to add arbitrary values
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAddRecipient(inputValue);
    }
  };

  return (
    <div>
      <h3>Recipients</h3>
      <div>
        {/* Input field to add arbitrary values */}
        <input
          type="text"
          placeholder="Add arbitrary email/phone"
          value={inputValue} // Bind inputValue to the input
          onChange={handleInputChange} // Use handleInputChange for arbitrary input
          onKeyDown={handleKeyDown} // Add arbitrary recipient on Enter key
        />
        <button onClick={() => handleAddRecipient(inputValue)}>Add</button>
      </div>

      {/* Filtered User List */}
      <ul>
        {filteredUsers.map((user) => (
          <li key={user.email}>
            {user.name}
            <button
              onClick={(e) => {
                e.preventDefault();
                handleAddRecipient(user.email);
              }}
            >
              Email
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                handleAddRecipient(user.phone);
              }}
            >
              Phone
            </button>
          </li>
        ))}
      </ul>

      {/* Selected Recipients */}
      <h4>Selected Recipients:</h4>
      <ul>
        {selectedRecipients.map((recipient) => (
          <li key={recipient}>
            {recipient}
            <button
              onClick={(e) => {
                e.preventDefault();
                handleRemoveRecipient(recipient);
              }}
            >
              Remove
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecipientsManager;
