import { useEffect, useState } from "react";
import errorLogger from "../../../helpers/errorLogger";
import useApi from "../../../helpers/useApi";
import ComboBox from "../../elements/ComboBox";
import { Item } from "react-stately";
import Button from "../../elements/Button";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function DevicesIndex() {
  const { callApi } = useApi();
  const navigate = useNavigate();

  const [deviceList, setDeviceList] = useState({
    data: [],
    loading: true,
    error: null,
  });

  const [typesList, setTypesList] = useState({
    data: [],
    loading: true,
    error: null,
  });

  const [clientsList, setClientsList] = useState({
    error: null,
    loading: true,
    data: [],
  });

  const [manufacturersList, setManufacturersList] = useState({
    error: null,
    loading: true,
    data: [],
  });

  const [modelsList, setModelsList] = useState({
    error: null,
    loading: false,
    data: [],
  });

  const [formEui, setFormEui] = useState("");
  const [formName, setFormName] = useState("");
  const [formClient, setFormClient] = useState("");
  const [formManufacturer, setFormManufacturer] = useState("");
  const [formModel, setFormModel] = useState("");
  const [formDeviceType, setFormDeviceType] = useState("");

  const [showActive, setShowActive] = useState(`["active"]`);
  const [showHidden, setShowHidden] = useState(`["visible"]`);

  const [euiFilter, setEuiFilter] = useState({ value: "", key: "" });
  const [nameFilter, setNameFilter] = useState({ value: "", key: "" });
  const [clientFilter, setClientFilter] = useState({ value: "", key: "" });
  const [deviceTypeFilter, setDeviceTypeFilter] = useState({
    value: "",
    key: "",
  });

  const updateDeviceList = async () => {
    try {
      const options = {
        path: "/devices",
        scope: "list:devices",
      };
      const newDeviceList = await callApi(options).then((res) => res.json());
      setDeviceList({
        ...deviceList,
        data: newDeviceList,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setDeviceList({
        ...deviceList,
        error,
        loading: false,
      });
    }
  };

  const updateManufacturersList = async () => {
    try {
      const options = {
        path: "/manufacturers",
        scope: "list:manufacturers",
      };
      const res = await callApi(options);
      setManufacturersList({
        ...manufacturersList,
        data: await res.json(),
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setManufacturersList({
        ...manufacturersList,
        error,
        loading: false,
      });
    }
  };

  const updateTypesList = async () => {
    try {
      const options = {
        path: "/device_types",
        scope: "list:device_types",
      };
      const res = await callApi(options);
      setTypesList({
        ...typesList,
        data: await res.json(),
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setTypesList({
        ...typesList,
        error,
        loading: false,
      });
    }
  };

  const updateClientsList = async () => {
    try {
      const options = {
        path: "/clients",
        scope: "list:clients",
      };
      const newList = await callApi(options).then((res) => res.json());
      setClientsList({
        ...clientsList,
        data: newList,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setClientsList({
        ...clientsList,
        error,
        loading: false,
      });
    }
  };

  useEffect(() => {
    updateDeviceList();
    updateManufacturersList();
    updateTypesList();
    updateClientsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateModelsList = async () => {
    try {
      const options = {
        path: "/device_models",
        scope: "list:device_models",
        query: { manufacturerId: formManufacturer },
      };
      const res = await callApi(options);
      setModelsList({
        data: await res.json(),
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setModelsList({
        ...modelsList,
        error,
        loading: false,
      });
    }
  };

  useEffect(() => {
    if (!modelsList.loading && !!formManufacturer) {
      setModelsList({
        ...modelsList,
        loading: true,
      });
      updateModelsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formManufacturer]);

  const handleAddDevice = async (event) => {
    event.preventDefault();
    if (
      ![
        formEui,
        formName,
        formClient,
        formManufacturer,
        formModel,
        formDeviceType,
      ].every((v) => v !== "")
    ) {
      alert("OH NO! Form is incomplete!");
      return;
    }

    console.log(formClient);
    console.log(clientsList.data);
    const selectedClient = clientsList.data.find(
      (c) => c.clientId === formClient
    );

    console.log(selectedClient);

    const requestBody = {
      eui: formEui.trim(),
      name: formName.trim(),
      clientId: formClient,
      location: selectedClient.location,
      modelId: formModel,
      deviceTypeId: formDeviceType,
    };

    try {
      const options = {
        path: `/devices`,
        scope: "create:devices",
        method: "POST",
        body: JSON.stringify(requestBody),
      };
      const newDevice = await callApi(options).then((res) => res.json());
      if (newDevice) {
        navigate(`/edit_device/${newDevice.id}`);
      } else {
        throw new Error("Failed to create device");
      }
    } catch (error) {
      errorLogger(error);
    }
  };

  const uniqueNames = Array.from(new Set(deviceList.data.map((d) => d.name)));

  const clearAllFilters = () => {
    setEuiFilter({ value: "", key: "" });
    setNameFilter({ value: "", key: "" });
    setClientFilter({ value: "", key: "" });
    setDeviceTypeFilter({ value: "", key: "" });
  };

  const deviceRows = deviceList.data
    .filter(
      (d) =>
        (!euiFilter.key || d.eui === euiFilter.key) &&
        (!nameFilter.key || d.name === nameFilter.key) &&
        (!clientFilter.key || d.clientId === clientFilter.key) &&
        (!deviceTypeFilter.key || d.deviceTypeId === deviceTypeFilter.key)
    )
    .map((d) => {
      return (
        <tr key={d.id}>
          <td>{d.eui}</td>
          <td>{d.name}</td>
          <td>{d.clientName}</td>
          <td>{d.deviceTypeName}</td>
          <td>
            <button
              onClick={() =>
                navigate(`/edit_device/${encodeURIComponent(d.id)}`)
              }
            >
              <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
            </button>
            <button onClick={() => navigate(`/devices/${d.id}/alerts`)}>
              <FontAwesomeIcon icon="fa-solid fa-bell" />
            </button>
          </td>
        </tr>
      );
    });
  return (
    <div className="Devices page">
      <h2>Devices</h2>
      <form onSubmit={handleAddDevice}>
        <div>
          <label>EUI</label>
          <input
            type="text"
            value={formEui}
            onChange={(e) => setFormEui(e.currentTarget.value)}
          ></input>
        </div>
        <div>
          <label>Name</label>
          <input
            type="text"
            value={formName}
            onChange={(e) => setFormName(e.currentTarget.value)}
          ></input>
        </div>
        <div>
          <label>Client</label>
          <select
            value={formClient}
            onChange={(e) => setFormClient(e.currentTarget.value)}
          >
            <option>Select client...</option>
            {clientsList.data.map((c) => (
              <option key={c.clientId} value={c.clientId}>
                {c.clientName}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Manufacturer</label>
          <select
            value={formManufacturer}
            onChange={(e) => setFormManufacturer(e.currentTarget.value)}
          >
            <option>Select manufacturer...</option>
            {manufacturersList.data.map((m) => (
              <option key={m.id} value={m.id}>
                {m.name}
              </option>
            ))}
          </select>
        </div>
        {!modelsList.loading && modelsList.data.length ? (
          <div>
            <label>Device Model</label>
            <select
              value={formModel}
              onChange={(e) => setFormModel(e.currentTarget.value)}
            >
              <option>Select model...</option>
              {modelsList.data.map((m) => (
                <option key={m.id} value={m.id}>
                  {m.name}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <></>
        )}
        <div>
          <label>Device Type</label>
          <select
            value={formDeviceType}
            onChange={(e) => setFormDeviceType(e.currentTarget.value)}
          >
            <option>Select device type...</option>
            {typesList.data.map((t) => (
              <option key={t.id} value={t.id}>
                {t.name}
              </option>
            ))}
          </select>
        </div>
        <Button type="submit">Add Device</Button>
      </form>
      <div>
        Show active devices?
        <select
          value={showActive}
          onChange={(e) => setShowActive(e.currentTarget.value)}
        >
          <option key="active" value='["active"]'>
            Active only
          </option>
          <option key="active and inactive" value='["active", "inactive"]'>
            Active and Inactive
          </option>
          <option key="inactive" value='["inactive"]'>
            Inactive only
          </option>
        </select>
      </div>
      <div>
        Show hidden devices?
        <select
          value={showHidden}
          onChange={(e) => setShowHidden(e.currentTarget.value)}
        >
          <option key="visible" value='["visible"]'>
            Visible only
          </option>
          <option key="visible and hidden" value='["visible", "hidden"]'>
            Visible and Hidden
          </option>
          <option key="hidden" value='["hidden"]'>
            Hidden only
          </option>
        </select>
      </div>
      <table>
        <thead>
          <tr key="headers">
            <th>EUI</th>
            <th>Name</th>
            <th>Client</th>
            <th>Type</th>
            <th></th>
          </tr>
          <tr key="filters">
            <td>
              <ComboBox
                aria-label="device-eui"
                selectedKey={euiFilter.key}
                inputValue={euiFilter.value}
                onSelectionChange={(type) =>
                  setEuiFilter({ key: type, value: type })
                }
                onInputChange={(value) =>
                  setEuiFilter((prev) => ({
                    value: value,
                    key: value === "" ? "" : prev.key,
                  }))
                }
              >
                {deviceList.data.map((d) => (
                  <Item key={d.eui}>{d.eui}</Item>
                ))}
              </ComboBox>
            </td>
            <td>
              <ComboBox
                aria-label="device-name"
                selectedKey={nameFilter.key}
                inputValue={nameFilter.value}
                onSelectionChange={(type) =>
                  setNameFilter({ key: type, value: type })
                }
                onInputChange={(value) =>
                  setNameFilter((prev) => ({
                    value: value,
                    key: value === "" ? "" : prev.key,
                  }))
                }
              >
                {uniqueNames.map((n) => (
                  <Item key={n}>{n}</Item>
                ))}
              </ComboBox>
            </td>
            <td>
              <ComboBox
                aria-label="device-client"
                selectedKey={clientFilter.key}
                inputValue={clientFilter.value}
                onSelectionChange={(c) => {
                  setClientFilter({ key: c, value: c.clientName });
                }}
                onInputChange={(value) =>
                  setClientFilter((prev) => ({
                    value: value,
                    key: value === "" ? "" : prev.key,
                  }))
                }
              >
                {clientsList.data.map((c) => (
                  <Item key={c.clientId}>{c.clientName}</Item>
                ))}
              </ComboBox>
            </td>
            <td>
              <ComboBox
                aria-label="device-type"
                selectedKey={deviceTypeFilter.key}
                inputValue={deviceTypeFilter.value}
                onSelectionChange={(t) => {
                  setDeviceTypeFilter({
                    key: t,
                    value: t.name,
                  });
                }}
                onInputChange={(value) => {
                  setDeviceTypeFilter((prev) => ({
                    value: value ?? "",
                    key: value === "" ? "" : prev.key,
                  }));
                }}
              >
                {typesList.data.map((t) => (
                  <Item key={t.id}>{t.name}</Item>
                ))}
              </ComboBox>
            </td>
            <td>
              <Button onPress={() => clearAllFilters()}>Clear All</Button>
            </td>
          </tr>
        </thead>
        <tbody>{deviceRows}</tbody>
      </table>
    </div>
  );
}

export default DevicesIndex;
