import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import errorLogger from "../../../helpers/errorLogger";
import useApi from "../../../helpers/useApi";
import { useAuth0 } from "@auth0/auth0-react";
import RecipientsManager from "../../elements/RecipientsManager";

const Alerts = () => {
  const { user } = useAuth0();
  const userRoles = user?.cyf_roles;
  const isAdmin = userRoles && userRoles.includes("Admin");

  const params = useParams();

  const [alertList, setAlertList] = useState({
    data: [],
    loading: true,
    error: null,
  });
  const [conditionInput, setConditionInput] = useState("");
  const [variableInput, setVariableInput] = useState("");
  const [thresholdInput, setThresholdInput] = useState("");
  const [hitsInput, setHitsInput] = useState("");
  const [resetDifferenceInput, setResetDifferenceInput] = useState("");
  const [recipientsInput, setRecipientsInput] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [activeInput, setActiveInput] = useState(true);
  const [device, setDevice] = useState({
    data: {},
    loading: true,
    error: null,
  });
  const [recipientsList, setRecipientsList] = useState({
    data: [],
    loading: true,
    error: null,
  });

  const { callApi } = useApi();
  const navigate = useNavigate();

  const resetInputs = () => {
    setConditionInput("");
    setVariableInput("");
    setThresholdInput("");
    setHitsInput("");
    setResetDifferenceInput("");
    setRecipientsInput([]);
    setMessageInput("");
    setActiveInput(true);
  };

  const handleCancel = () => resetInputs();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const requestBody = {
        device_id: params.deviceId,
        condition: conditionInput,
        variable: variableInput,
        threshold: parseFloat(thresholdInput),
        hits: parseInt(hitsInput, 10),
        reset_difference: parseFloat(resetDifferenceInput),
        recipients: recipientsInput,
        message: messageInput,
        active: activeInput,
      };
      const options = {
        path: "/alerts",
        scope: "create:alerts",
        method: "POST",
        body: JSON.stringify(requestBody),
      };
      const newAlert = await callApi(options).then((res) => res.json());
      const newList = [...alertList.data, newAlert];
      setAlertList({
        ...alertList,
        data: newList,
      });
      resetInputs();
    } catch (error) {
      errorLogger(error);
    }
  };

  const handleEdit = (alert, _event) => {
    const alertId = encodeURIComponent(alert.alertId);
    navigate(`/edit_alert/${alertId}`);
  };

  const fetchDevice = async () => {
    try {
      const options = {
        path: `/devices/${params.deviceId}`,
        scope: "read:devices",
      };
      const newDevice = await callApi(options).then((res) => res.json());

      setDevice({
        ...device,
        data: newDevice,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setDevice({
        ...device,
        error,
        loading: false,
      });
    }
  };

  const fetchRecipients = async () => {
    try {
      const options = {
        path: `/alerts/available_recipients`,
        scope: "list:alerts:recipients",
      };
      const recipients = await callApi(options).then((res) => res.json());

      setRecipientsList({
        ...recipientsList,
        data: recipients,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setRecipientsList({
        ...recipientsList,
        error,
        loading: false,
      });
    }
  };

  const fetchAlerts = async () => {
    try {
      const options = {
        path: "/alerts",
        scope: "list:alerts",
        query: { deviceId: params.deviceId },
      };
      const alerts = await callApi(options).then((res) => res.json());
      setAlertList({
        ...alertList,
        data: alerts,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setAlertList({
        ...alertList,
        error,
        loading: false,
      });
    }
  };

  useEffect(() => {
    fetchDevice();
    fetchRecipients();
    fetchAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const alertRows = alertList.loading ? (
    <tr>
      <td colSpan="7">Loading...</td>
    </tr>
  ) : (
    alertList.data.map((alert) => (
      <tr key={alert.id}>
        <td>{alert.condition}</td>
        <td>{alert.variable}</td>
        <td>{alert.threshold}</td>
        <td>{alert.hits}</td>
        <td>{alert.reset_difference}</td>
        <td>{alert.recipients.join(", ")}</td>
        <td>{alert.message}</td>
        <td>{alert.active ? "Active" : "Inactive"}</td>
        <td>
          <button onClick={(e) => handleEdit(alert, e)}>Edit</button>
        </td>
      </tr>
    ))
  );

  const variableOptions = () => {
    const variableMap = {
      "Channel Level": ["Depth"],
    };

    return variableMap[device.data.deviceTypeName] || [];
  };

  return (
    <div className="Alerts page">
      <h2>Alerts</h2>
      <h3>Device: {device.data.name}</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="alert-condition">Condition</label>
          <select
            id="alert-condition"
            value={conditionInput}
            onChange={(e) => setConditionInput(e.currentTarget.value)}
          >
            <option key="empty-condition">Select condition...</option>
            <option key="low" value="low">
              Low
            </option>
            <option key="high" value="high">
              High
            </option>
          </select>
        </div>
        <div>
          <label htmlFor="alert-variable">Variable</label>
          <select
            id="alert-variable"
            value={variableInput}
            onChange={(e) => setVariableInput(e.currentTarget.value)}
          >
            <option key="empty-variable">Select variable...</option>
            {variableOptions().map((v) => (
              <option key={v.toLowerCase()} value={v}>
                {v}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="alert-threshold">Threshold</label>
          <input
            id="alert-threshold"
            type="number"
            value={thresholdInput}
            onChange={(e) => setThresholdInput(e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="alert-hits">Hits</label>
          <input
            id="alert-hits"
            type="number"
            value={hitsInput}
            onChange={(e) => setHitsInput(e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="alert-reset-difference">Reset Difference</label>
          <input
            id="alert-reset-difference"
            type="number"
            value={resetDifferenceInput}
            onChange={(e) => setResetDifferenceInput(e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="alert-recipients">Recipients (comma-separated)</label>
          {recipientsList.loading ? (
            <span>Loading...</span>
          ) : (
            <RecipientsManager
              users={recipientsList.data}
              recipients={recipientsInput}
              onRecipientsChange={setRecipientsInput}
            />
          )}
        </div>
        <div>
          <label htmlFor="alert-message">Message</label>
          <input
            id="alert-message"
            type="text"
            value={messageInput}
            onChange={(e) => setMessageInput(e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="alert-active">Active</label>
          <input
            id="alert-active"
            type="checkbox"
            checked={activeInput}
            onChange={(e) => setActiveInput(e.currentTarget.checked)}
          />
        </div>
        <input type="submit" value="Save" />
        <input type="button" value="Cancel" onClick={handleCancel} />
      </form>

      <table>
        <thead>
          <tr>
            <th>Condition</th>
            <th>Variable</th>
            <th>Threshold</th>
            <th>Hits</th>
            <th>Reset Difference</th>
            <th>Recipients</th>
            <th>Message</th>
            <th>Active</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{alertRows}</tbody>
      </table>
    </div>
  );
};

export default Alerts;
